import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import axios from 'axios';
import * as QueryString from 'query-string';
import { AppContext } from 'AppContext';

const SignUp = (props) => {

    // Retrieve global configuration from application context:s
    const { config } = useContext(AppContext);
    // Retrieve history router for redirecting the user:
    const [history] = useState(useHistory());

    const [state, setState] = useState({ busy: false, event: "ready", data: { foo: "bar"}});

    const params = QueryString.parse(props.location.search);

    const [lastName, setLastName] = useState(params.lastname ? params.lastname : "");
    const [firstName, setFirstName] = useState(params.firstname ? params.firstname : "");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState(params.email ? params.email : "");
    const [phone, setPhone] = useState(params.phone ? params.phone : "");
    const [password, setPassword] = useState("");


    useEffect(()=>{
        switch(state.event) {
            case "submit" :
                onEnterStateSubmit();
                break;
            case "redirect" :
                onEnterStateRedirect();
                break;
        }
    }, [state])

    function onEnterStateSubmit(){
        const action = async () => {
            try {
                // Submit the form to the server:
                const response = await axios.post(config.api.base + "/users", {
                    firstName   : firstName,
                    lastName    : lastName,
                    company     : company,
                    password    : password,
                    phone       : phone,
                    email       : email
                })
                // Redirect the use to the sign-in page:
                setState({ busy: false, event: "redirect", data: {}})
            } catch(x) {
                console.log(x.response.data)
            }
        }
        action();
    }

    function onEnterStateRedirect() {
        console.log("REDIRECTING TO SIGN UP COMPLETION")
        history.push("/sign-up/complete");
    }

    function onSubmit(e) {
        console.log("ON SUBMIT")
        setState({ busy: true, event: "submit", data: {} })
        e.preventDefault();
    }

    function onChangeFirstName(e) {
        setFirstName(e.target.value);
    }

    function onChangeLastName(e) {
        setLastName(e.target.value);
    }

    function onChangeCompany(e) {
        setCompany(e.target.value);
    }

    function onChangePhone(e) {
        setPhone(e.target.value);
    }

    function onChangeEmail(e) {
        setEmail(e.target.value);
    }

    function onChangePassword(e) {
        setPassword(e.target.value);
    }

    return(
        <div className="min-h-screen flex justify-center bg-gray-50 pt-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Create a new account
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={onSubmit}>
                    <div className={"flex w-full space-x-6"}>
                        <div className={"w-full"}>
                            <label htmlFor="first-name">First name</label>
                            <input id="first-name" name="firstName" error={"true"}  type="text" autoComplete="given-name" value={firstName} required
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                   placeholder="First name"
                                   onChange={onChangeFirstName}
                            />
                        </div>
                        <div className={"w-full"}>
                            <label htmlFor="last-name">Last name</label>
                            <input id="last-name" name="lastName" type="text" autoComplete="family-name" required
                                   value={lastName}
                                   onChange={onChangeLastName}
                                   className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                   placeholder="Last name"/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="company">Company</label>
                        <input id="company" name="company" type="text" autoComplete="company"
                               required
                               value={company}
                               onChange={onChangeCompany}
                               className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                               placeholder="Company"/>
                    </div>
                    <div>
                        <label htmlFor="email">Email address</label>
                        <input id="email" name="email" type="email" autoComplete="email"
                               required
                               value={email}
                               onChange={onChangeEmail}
                               className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                               placeholder="Email"/>
                    </div>
                    <div>
                        <label htmlFor="phone">Phone number</label>
                        <input id="phone" name="email" type="text" autoComplete="phone"
                               required
                               value={phone}
                               onChange={onChangePhone}
                               className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                               placeholder="Phone number"/>
                    </div>
                    <div>
                        <label htmlFor="password">Password</label>
                        <input id="password" name="password" type="password"
                               required
                               value={password}
                               onChange={onChangePassword}
                               className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                               placeholder="Password"/>
                    </div>
                    <div>
                        <button type={"submit"} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Sign Up
                        </button>
                    </div>
                    <div className="flex items-center justify-center">
                        <p className="mt-2 text-center text-sm text-gray-600">
                            already have an account?
                            <NavLink to={"/sign-in"} className="ml-1 font-medium text-indigo-600 hover:text-indigo-500">
                                click here to login...
                            </NavLink>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SignUp;