import React, {useState, useContext, useEffect} from "react";
import Dashview from "../Dashview";
import axios from 'axios';
import { v4 } from 'uuid';
import {useHistory, Redirect} from "react-router-dom";
import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';
import {AppContext} from "AppContext";

const Content = (props) => {
    return (
        <div className={"container mx-auto"}>
            <div className={"border-b-2 border-solid py-4"}>
                <div>
                    <span className={"text-3xl"}>Account Information</span>
                </div>
            </div>
            <div className="mt-8 flex flex-col justify-start items-start w-full space-y-8 ">
                <div className={"flex flex-row justify-start items-start w-full space-x-8"}>
                    <div className={"w-full"}>
                        <label htmlFor="first-name">First name</label>
                        <input id={"first-name"} className="standardInput" type="text" placeholder="First Name" />
                    </div>
                    <div className={"w-full"}>
                        <label htmlFor="last-name">First name</label>
                        <input id={"last-name"} className="standardInput" type="text" placeholder="Last Name" />
                    </div>
                </div>
                <input className="standardInput" type="text" placeholder="Company" />
                <input className="standardInput" type={"text"} placeholder={"Email Address"} />
                <input className="standardInput" type={"text"} placeholder={"Phone Number"} />
                <div className={"flex flex-row justify-start items-start w-full space-x-8"}>
                    <input className="standardInput" type="text" placeholder="City" />
                    <input className="standardInput" type="text" placeholder="State" />
                    <input className="standardInput" type="text" placeholder="Postal Code" />
                </div>

            </div>
        </div>
    )
}

const Account = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Account;